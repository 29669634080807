import React from 'react'
import PropTypes from "prop-types";


const SEO = ( {title} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title} | C4S infra technology - Bridging the gaps.</title>
            <meta name="robots" content="noindex, follow" />
            <meta name="description" content="C4S Infra: Transforming communities with affordable long-range wireless connectivity, utilizing cutting-edge technology to meet diverse needs" />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;