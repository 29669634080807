import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ServiceData from "../data/service/ServiceMain.json";
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';
import Tilty from 'react-tilty';




const ServiceDetails = () => {

    return (
        <>
        <SEO title="Services" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerOne 
            title="Services"
            page="Services"
            paragraph={`At C4S, we provide software development, telemedicine advancements, smart education solutions, long-range connectivity, and advanced surveillance systems, all aimed at shaping a smarter, connected future.`}
            styleClass=""
            mainThumb="/images/services/services_1.png"
            />
            
           
            <div className="section section-padding bg-color-light pb--70">
               
                <div className="container">
                    {ServiceData.map((data, index) => (
                        <div key={index} className={`process-work ${(index % 2  === 1) ? "content-reverse" : ""}`}>
                            <Tilty perspective={2000}>
                                <div className="thumbnail">
                                    <img src={ data.image} alt="Thumbnail" />
                                </div>
                            </Tilty>
                        <div className="content">
                            <h3 className="title">{data.title}</h3>
                            <p style={{lineHeight:'2rem'}} >{data.description}</p>
                        </div>
                    </div>
                    ))}
                </div>
                <ul className="shape-group-17 list-unstyled">
                    <li className="shape shape-1"><img src={  "/images/others/bubble-24.png"} alt="Bubble" /></li>
                    <li className="shape shape-2"><img src={  "/images/others/bubble-23.png"} alt="Bubble" /></li>
                    <li className="shape shape-3"><img src={  "/images/others/line-4.png"} alt="Line" /></li>
                    <li className="shape shape-4"><img src={  "/images/others/line-5.png"} alt="Line" /></li>
                    <li className="shape shape-5"><img src={  "/images/others/line-4.png"} alt="Line" /></li>
                    <li className="shape shape-6"><img src={  "/images/others/line-5.png"} alt="Line" /></li>
                </ul>
            </div>
            <div className="section section-padding">
                <ul className="shape-group-16 list-unstyled">
                    <li className="shape shape-1"><img src={  "/images/others/circle-2.png"} alt="circle" /></li>
                    {/* <li className="shape shape-3"><img src={  "/images/others/bubble-1.png"} alt="Line" /></li> */}
                </ul>
            </div>
            <CtaLayoutOne />
        <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default ServiceDetails;