import React, { useState } from 'react';
import NewsData from "../../data/news/NewsList.json";
import {  FaAngleRight, FaAngleLeft,} from "react-icons/fa";

import Slider from "react-slick";

const allNewsData = NewsData;


const BlogGridOne = () => {
    
    function SlickNextArrow(props) {
        const { className, onClick } = props;
        return (
          <div className={className} onClick={onClick}><FaAngleRight /></div>
        );
    }

    function SlickPrevArrow(props) {
        const { className, onClick } = props;
        return (
          <div className={className} onClick={onClick}><FaAngleLeft /></div>
        );
    }

    var slideSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SlickNextArrow />,
        prevArrow: <SlickPrevArrow />,
       
    }

    const [newsList] = useState(allNewsData);


    return (
        <>
            {newsList.map((data) => (
                <div className="blog-grid" key={data.id}>
                    <h3 className="title" style={{lineHeight:'3.5rem'}}>
                        {data.title}
                    </h3>
                    <div className="author">
                        <div className="info">
                            <ul className="blog-meta list-unstyled">
                                <li>{data.post_date}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="post-thumbnail">
                        {
                            Array.isArray(data.thumbnail) ? 
                            <Slider {...slideSettings} className="slick-arrow-nav">
                                {data.thumbnail.map((data, index) => (
                                    <div className="slide-item" key={index}>
                                        <img src={`${data}`} alt="Blog" />
                                    </div>
                                ))}
                                
                            </Slider> 
                            : <img src={` /${data.thumbnail}`} alt="Blog" />
                        }
                        
                    </div>
                    <p style={{lineHeight:'2.3rem'}}>{data.description}</p>
                    <a target='_blank' href={`${data.link}`} className="axil-btn btn-borderd btn-large" >Read More</a>
                </div>
            ))}

        </>
    )
}

export default BlogGridOne;