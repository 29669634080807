import React from 'react';

const Data = [
    {
        // image: "/images/brand/brand-1.png"
        image: "/images/brand/tribalDepartmentOfTamilnadu@3x.png",
    },
    {
        image: "/images/brand/districtAdministrationOfTamilnadu@3x.png",
    },
    {
        image: "/images/brand/keralaForest@3x.png",
    },
    {
        image: "/images/brand/lsgd@3x.png",
    },
    {
        image: "/images/brand/departmentOdEducation@3x.png",
    },
    {
        image: "/images/brand/kite@3x.png",
    }
]


const BrandItem = () => {
    return (
        <>
            {Data.map((data, index) => (
                <div className="col-lg-3 col-6" style={{minWidth:'33%'}} key={index}>
                    <div className="brand-grid">
                        <img src={  data.image} alt="Brand" />
                    </div>
                </div>
            ))}
        </>
    )
}

export default BrandItem;