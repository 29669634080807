import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import ProcessOne from '../component/process/ProcessOne';
// import AboutThree from '../component/about/AboutThree';
// import AboutFour from '../component/about/AboutFour';
// import AboutFive from '../component/about/AboutFive';


const AboutUs = () => {

    return (
        <>
        <SEO title="About us" />
        <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BcrumbBannerOne 
                title="One of the fastest growing agency"
                paragraph ="We, the c4s infra, a Socio-technology oriented company, was founded in 2010 and reconstituted in 2019. Our primary objective is to improve telemedicine and smart education, along with implementing mentoring programs in rural villages and tribal schools through the provision of long-range wireless connectivity. Furthermore, we provide various services designed to narrow the gap between underprivileged and privileged societies."
                styleClass="thumbnail-4"
                mainThumb="/images/about/aboutUs_1.png"
                />
                <ProcessOne />
                <CtaLayoutOne />
                <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default AboutUs;