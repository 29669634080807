import React from 'react';
import { Link } from 'react-router-dom';
import ProjectData from '../../data/project/ProjectData.json';
import Tilty from 'react-tilty';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';
import { slugify } from '../../utils';


const CaseStudyProp = () => {
    
    const CaseLeftThumb = ({data}) => {
        return (
            <>
            <div className="col-lg-6">
                <div className={`case-study-featured-thumb thumb-${data.id}`}>
                <Tilty perspective={2000}>
                    <img src={  data.image}  />
                </Tilty>
                </div>
            </div>
            <div className="col-xl-5 col-lg-6 offset-xl-1">
                <div className="case-study-featured">
                <div className="section-heading heading-left">
                    <h3 className="title">{data.title}</h3>
                    <p style={{lineHeight:'2rem'}}>{data.description}</p>
                    {/* <Link to={  `/contact`} className="axil-btn btn-fill-primary btn-large">Enquire more</Link> */}
                </div>
                {
                    data.count_1
                    ? (
                        <div className="case-study-counterup">
                            <div className="single-counterup">
                                <div className="count-number h3">
                                    <TrackVisibility once>
                                        {({isVisible}) => (
                                            <span className="number count">
                                                {isVisible ? <CountUp end={data.count_1} duration={1} /> : null}
                                            </span>
                                        )}  
                                    </TrackVisibility>
                                    {
                                        data.unit
                                        ? <span className="symbol">{` ${data.unit}`}</span>
                                        : null 
                                    }
                                    
                                </div>
                                {
                                    data.countLabel
                                    ? <span className="counter-title">{data.countLabel}</span>
                                    : null
                                }
                                
                            </div>
                        
                        </div>
                    )
                    : null
                }
                </div>
            </div>
            </>
        )
    }
    
    const CaseRightThumb = ({data}) => {
        return (
            <>
            <div className="col-lg-6 offset-xl-1 order-lg-2">
                <div className={`case-study-featured-thumb thumb-${data.id}`}>
                <Tilty perspective={2000}>
                    <img src={  data.image}  />
                </Tilty>
                </div>
            </div>
            <div className="col-lg-5 order-lg-1">
                <div className="case-study-featured">
                <div className="section-heading heading-left">
                    <h3 className="title">{data.title}</h3>
                    <p style={{lineHeight:'2rem'}} >{data.description}</p>
                    {/* <Link to={  `/contact`} className="axil-btn btn-fill-primary btn-large">Enquire more</Link> */}
                </div>
                {
                    data.count_1
                    ? (
                        <div className="case-study-counterup">
                            <div className="single-counterup">
                                <div className="count-number h3">
                                    <TrackVisibility once>
                                        {({isVisible}) => (
                                            <span className="number count">
                                                {isVisible ? <CountUp end={data.count_1} duration={1} /> : null}
                                            </span>
                                        )}  
                                    </TrackVisibility>
                                    {
                                        data.unit
                                        ? <span className="symbol">{` ${data.unit}`}</span>
                                        : null 
                                    }
                                    
                                </div>
                                {
                                    data.countLabel
                                    ? <span className="counter-title">{data.countLabel}</span>
                                    : null 
                                }
                                
                            </div>
                        
                        </div>

                    )
                    : null
                }
                </div>
            </div>
            </>
        )
    }

    return (
        <>
            {ProjectData.map((data) => (
                <div className="row" key={data.id}> 
                    {(data.id % 2  === 0) ? <CaseRightThumb data={data}/> : <CaseLeftThumb data={data}/>}
                </div>
            ))}
        </>
    )
}

export default CaseStudyProp;