import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderTwo from '../common/header/HeaderTwo';
import SEO from '../common/SEO';
import BcrumbBannerOne from '../elements/breadcrumb/BcrumbBannerOne';
import BannerTwo from '../component/banner/BannerTwo';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';

import RecognitionsData from "../data/recognitions/recognitionsData.json";

const CreativeAgency = () => {

    return (
        <>
        <SEO title="Recognitions"/>
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderTwo />
            <BcrumbBannerOne 
                title="Recognitions"
                page="Recognitions"
                styleClass=""
                mainThumb={null}
            />
            <div className='container' >
                <div className="section section-padding">
                    <div className={`row row-35`}>
                        {RecognitionsData.map((data) => (
                                <div className={"col-md-3"} key={data.id}>
                                <div className={`project-grid `} style={{borderRadius:'10px'}} >
                                <div className="thumbnail" style={{borderRadius:'10px'}}>
                                    <img src={  data.image} alt="icon" style={{borderRadius:'10px'}} />
                                </div>
                            </div>
                            </div>
                        ))}
                    </div>
                </div>

            </div>

            <CtaLayoutOne />
            <FooterOne parentClass="" />
        </main>
        </>
    )
}

export default CreativeAgency;

