import React from 'react';
import { Link } from 'react-router-dom';


const Logo = ({limage, dimage, simage}) => {
    return (
        <Link to={  "/"}>
            <img className="light-version-logo" src={  limage} alt="logo" style={{height:'80px'}} />
            <img className="dark-version-logo" src={  dimage} alt="logo" style={{height:'80px'}} />
            <img className="sticky-logo" src={  simage} alt="logo" style={{height:'80px'}} />
        </Link>
    )
}


export default Logo;