import React from 'react';
import { Link } from 'react-router-dom';
import { 
    FaFacebookF, FaPinterestP, FaLinkedin, FaInstagram, 
    FaYoutube,
    // FaVimeoV, FaDribbble, FaBehance, FaEnvelopeOpen, FaTiktok 
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import PagesData from "../../data/pages/pagesList.json";
import { slugify } from '../../utils';



const FooterOne = ({parentClass}) => {
    
    return (
        <footer className={`footer-area ${parentClass}`}>
            <div className="container">
                <div className="footer-top">
                    <div className="footer-social-link">
                        <ul className="list-unstyled">
                            <li><a target='_blank' rel='noopener noreferrer' href="https://www.facebook.com/profile.php?id=61556561852623"><FaFacebookF /></a></li>
                            <li><a target='_blank' rel='noopener noreferrer' href="https://twitter.com/c4sinfra"><FaXTwitter /></a></li>
                            <li><a target='_blank' rel='noopener noreferrer' href="https://www.youtube.com/channel/UC6dPRVN7P5PLpp3XxFIROjw" ><FaYoutube /></a></li>
                            <li><a ><FaPinterestP /></a></li>
                            <li> <a><FaLinkedin /> </a> </li>
                            <li><a><FaInstagram /></a> </li>
                        </ul>
                    </div>
                </div>
                <div className="footer-main">
                    <div className="row">
                        <div className="col-xl-6 col-lg-5">
                            <div className="footer-widget border-end">
                                <div className="footer-newsletter">
                                    <h2 className="title">Get in touch!</h2>
                                    <p>Would like to know more?</p>
                                    <form>
                                        <Link to={  "/contact"}>
                                            <button className="subscribe-btn" type="submit" >Contact us</button>
                                        </Link>
                                        
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-7">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Pages</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                {PagesData.slice(0, 7).map((data, index) => (
                                                    <li key={index}>
                                                        <Link to={  `/${slugify(data.url)}`}>{data.title}</Link>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="col-sm-3">
                                    <div className="footer-widget">
                                        <h6 className="widget-title">Support</h6>
                                        <div className="footer-menu-link">
                                            <ul className="list-unstyled">
                                                <li><Link to={  "/contact"}>Contact</Link></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom"> 
                    <div className="row">
                        <div className="col-md-6">
                            <div className="footer-copyright">
                                <span className="copyright-text">© {new Date().getFullYear()}. All rights reserved by C4s Infra.</span>
                            </div>
                        </div>
                        {/* <div className="col-md-6">
                            <div className="footer-bottom-link">
                                <ul className="list-unstyled">
                                    <li><Link to={  "/privacy-policy"}>Privacy Policy</Link></li>
                                    <li><Link to={  "/terms-use"}>Terms of Use</Link></li>
                                </ul>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default FooterOne;