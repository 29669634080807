import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleRight } from "react-icons/fa";
import AboutUsData from "../../data/aboutUs/AboutUsData.json";


const allAboutUsData = AboutUsData;

const BlogListOne = ({colSize, itemShow, itemSkip}) => {
    return (
        <>
            {allAboutUsData.slice(itemSkip, itemShow).map((data) => (
                <div className={`${colSize}`} key={data.id}>
                    <div className={`blog-list ${(data.id % 2  === 0) ? "border-start" : ""}`}>
                        <div className="post-thumbnail">
                            <img src={` /${data.thumb}`} alt="Blog Post" />
                        </div>
                        <div className="post-content">
                            <h5 className="title">
                                {data.title}
                            </h5>
                            <p>{data.description}</p>
                            <Link to={  `/about-us`} className="more-btn">
                                Learn more <FaAngleRight />
                            </Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default BlogListOne;