import React from 'react';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';


// Home Pages Import
import HomePage from './pages/HomePage';
import ServicesPage from './pages/ServicesPage';
import AboutUs from './pages/AboutUs';
import ProjectsPage from './pages/ProjectsPage';
import OurClientsPage from './pages/OurClientsPage';
import RecognitionsPage from './pages/RecognitionsPage';
import NewsPage from './pages/NewsPage';
import Contact from './pages/Contact';

// Css Import
import './assets/scss/app.scss';


const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={"/services"} element={<ServicesPage />}/>
				<Route path={"/projects"} element={<ProjectsPage/>}/>
				<Route path={"/about-us"} element={<AboutUs />}/>
				<Route path={"/clients"} element={<OurClientsPage />}/>
				<Route path={"/contact"} element={<Contact />}/>
				<Route path={"/recognitions"} element={<RecognitionsPage />}/>
				<Route path={"/news"} element={<NewsPage />}/>
				<Route path={"/"} element={<HomePage />}/>
			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
