import React from 'react';
import { Link } from 'react-router-dom';
// import { FaAngleDown } from "react-icons/fa";
import PagesData from "../../data/pages/pagesList.json";
import { slugify } from '../../utils';

const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu" style={{justifyContent:'flex-end'}}>
                {PagesData.slice(0, 8).map((data, index) => (
                    <li key={index}>
                        <Link to={  `/${slugify(data.url)}`}>{data.title}</Link>
                    </li>
                ))}
            </ul>
        </nav>
    )
}

export default Nav;