import React, { useState } from 'react';
import { FaPlay } from 'react-icons/fa';
import FsLightbox from 'fslightbox-react';


const VideoOne = () => {

    const [toggler, setToggler] = useState(false);

    return (
        <>
            <div className="section section-padding-equal bg-color-light">
                <div className="container">
                    <div className="about-expert">
                        <div className="thumbnail">
                            <img src={  "/images/about/thumbnail@2x.png"} alt="Thumbnail" />
                            <div className="popup-video">
                                <button className="play-btn" onClick={ () => setToggler(!toggler) }><FaPlay /></button>
                            </div>
                        </div>
                    </div>
                    
                        <div className='section-heading'  >
                            <div className='subtitle' style={{marginTop:'10px'}} >Kathirimali Village, Erode - An amazing story of transforming tribal and remote India</div>
                            <div style={{lineHeight:'2rem'}}  >To socially upbring the Kathirimalai tribal community and help them at par with urban communities, the Erode district administration DRDA teamed up with NABARD and Technology partner with C4S on a major connectivity project, Punnagai. Kathirimalai village, 81km from Erode with no proper road access or electricity or telecommunication facilities was connected with with long distance high speed wifi network, provided telemedicine facilities and set up smart school education at the tribal residential school.</div>

                        </div>

                </div>
                <ul className="shape-group-16 list-unstyled">
                    <li className="shape shape-1"><img src={  "/images/others/circle-2.png"} alt="circle" /></li>
                    <li className="shape shape-2"><img src={  "/images/others/bubble-2.png"} alt="Line" /></li>
                    <li className="shape shape-3"><img src={  "/images/others/bubble-1.png"} alt="Line" /></li>
                </ul>
        </div>

        <FsLightbox toggler={ toggler } sources={ ['https://www.youtube.com/watch?v=xmeFPRKjBEE'] }/>
    </>
    )
}


export default VideoOne;